import { createGlobalState } from 'react-hooks-global-state';

const initialState = {
  openNav: false,
  userId: '',
  notificationGallery: false,
  notificationEvents: false,
  suiteName: '',
  events: null,
  games: null,
};

const {
  GlobalStateProvider,
  setGlobalState,
  useGlobalState,
} = createGlobalState(initialState);

export const openNav = () => {
  setGlobalState('openNav', () => true);
};
export const closeNav = () => {
  setGlobalState('openNav', () => false);
};

export { GlobalStateProvider, useGlobalState };
