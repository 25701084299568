/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from 'react';
import { openNav } from '../../contexts/GlobalState';

import { clientInfoContext } from '../../contexts/ClientInfoContext';

import styles from './Home.scss';

import videoBg from '../../videos/home-bg.mp4';
import videoBgMobile from '../../videos/home-bg-m.gif';
import videoBgCover from '../../images/video-cover.jpg';
import homeBtnArrow from '../../images/icons/home-btn-arrow.svg';

// import { useRect } from '../../helpers/getWidth';

const HomePage = props => {
  const { client } = props;
  const [desktop, setDesktop] = useState();

  useEffect(() => {
    typeof window.orientation !== 'undefined' ||
    navigator.userAgent.indexOf('IEMobile') !== -1
      ? setDesktop(false)
      : setDesktop(true);
  }, [desktop]);
  return (
    <div className={styles.home}>
      {/* <div className={styles.home__video}> */}
      <div className={styles['vimeo-wrapper']}>
        <img src={videoBgCover} alt="" />
        <iframe
          src="https://player.vimeo.com/video/969164750?h=98abd63f0a&background=1&autoplay=1&loop=1&byline=0&title=0&player_id=0&app_id=58479"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
        />
      </div>
      {/* {desktop ? (
          <video
            id="full-video"
            muted
            preload="true"
            autoPlay
            loop
            playsInline
            poster={videoBgCover}
          >
            <source src={videoBg} type="video/mp4" />
          </video>
        ) : (
          <img src={videoBgMobile} alt="" />
        )} */}
      {/* </div> */}
      <div className={styles.home__content}>
        <h4>{client.owner_name ? client.owner_name + ' –' : ''}</h4>
        <h2>
          welcome to your
          <br />
          <span>Premium Experience</span>
        </h2>
        <a
          className={styles.home__content__btn}
          role="button"
          tabIndex={0}
          onClick={() => openNav()}
        >
          <svg
            width="270"
            height="60"
            viewBox="0 0 270 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0.5 59.5H228.69L254.239 0.5H0.5V59.5Z" stroke="white" />
          </svg>
          <p>
            manage
            <i>
              <img src={homeBtnArrow} alt="" />
            </i>
          </p>
        </a>
      </div>
    </div>
  );
};

export default React.forwardRef((props, ref) => (
  <clientInfoContext.Consumer>
    {client => <HomePage {...props} client={client} ref={ref} />}
  </clientInfoContext.Consumer>
));
