import React from 'react';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import styles from './Button.scss';

const ButtonBase = ({
  text,
  href = '#',
  type = 'button',
  btnType,
  btnSize,
  disabled,
  externalLink,
  icon = '',
}) => {
  const Button = (
    <button
      className={cx(
        styles.btn,
        styles[`btn--${btnType}`],
        styles[`btn--${btnSize}`],
      )}
      type={type}
      disabled={disabled}
    >
      {href === 'soon' ? 'reserve this event soon' : text}
      {icon === 'arrow' && (
        <i>
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
            <path d="M7 0l7 7-7 7-1.237-1.237 4.884-4.904H0V6.141h10.647L5.763 1.237z" />
          </svg>
        </i>
      )}
      {icon === 'ticket' && (
        <i>
          <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20">
            <path d="M11.143 5.204l3.438 3.439-6.224 6.224L4.92 11.43l6.224-6.225zM8.847 15.857l6.725-6.724a.69.69 0 0 0 0-.98l-3.94-3.939a.71.71 0 0 0-.979 0l-6.725 6.725a.69.69 0 0 0 0 .98l3.94 3.938c.13.131.304.196.49.196.184 0 .358-.065.489-.196zm9.674-6.931l-9.87 9.88a1.413 1.413 0 0 1-1.97 0l-1.37-1.37a2.095 2.095 0 0 0 0-2.96 2.095 2.095 0 0 0-2.96 0L.99 13.103a1.392 1.392 0 0 1 0-1.97l9.869-9.858a1.392 1.392 0 0 1 1.97 0l1.36 1.36a2.095 2.095 0 0 0 0 2.96 2.095 2.095 0 0 0 2.96 0l1.37 1.36a1.413 1.413 0 0 1 0 1.97z" />
          </svg>
        </i>
      )}
      {icon === 'person' && (
        <i>
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16">
            <path d="M7.725 9.894c.887 0 1.859.129 2.917.386 1.201.3 2.174.701 2.917 1.202.887.586 1.33 1.244 1.33 1.973v1.802H.561v-1.802c0-.73.443-1.387 1.33-1.973.743-.5 1.716-.901 2.917-1.202 1.058-.257 2.03-.386 2.917-.386zm0-1.801c-.644 0-1.237-.161-1.78-.483a3.64 3.64 0 0 1-1.298-1.298 3.431 3.431 0 0 1-.483-1.78c0-.644.161-1.244.483-1.802a3.59 3.59 0 0 1 1.298-1.32 3.431 3.431 0 0 1 1.78-.482c.644 0 1.237.161 1.78.483a3.59 3.59 0 0 1 1.298 1.319c.322.558.483 1.158.483 1.802 0 .643-.161 1.237-.483 1.78A3.64 3.64 0 0 1 9.505 7.61a3.431 3.431 0 0 1-1.78.483z" />
          </svg>
        </i>
      )}
      {icon === 'chat' && (
        <i>
          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17">
            <path d="M13.625 4.938V3.3h-9.75v1.638h9.75zm0 2.438V5.738h-9.75v1.638h9.75zm0 2.437V8.175h-9.75v1.638h9.75zm3.237-8.151v14.625l-3.237-3.237H2.237a1.51 1.51 0 0 1-1.131-.487 1.606 1.606 0 0 1-.468-1.151v-9.75c0-.442.156-.819.468-1.131A1.54 1.54 0 0 1 2.237.063h13.026c.442 0 .819.156 1.131.468.312.312.468.689.468 1.131z" />
          </svg>
        </i>
      )}
      {icon === 'camera' && (
        <i>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14">
            <path d="M7.678 9.8c-1.44 0-2.16-.719-2.16-2.159s.72-2.16 2.16-2.16c1.44 0 2.16.72 2.16 2.16 0 1.44-.72 2.16-2.16 2.16zm6.307-8.466c.366 0 .686.137.96.412.274.274.411.594.411.96v9.323c0 .366-.137.685-.411.96a1.315 1.315 0 0 1-.96.411H1.371c-.366 0-.685-.137-.96-.411a1.315 1.315 0 0 1-.411-.96V2.705c0-.365.137-.685.411-.96.275-.274.594-.41.96-.41h3.017L5.484.236h4.388l1.097 1.097h3.016zM4.97 10.35c.754.754 1.657 1.131 2.708 1.131 1.051 0 1.954-.377 2.708-1.13.754-.755 1.131-1.657 1.131-2.709 0-1.05-.377-1.953-1.131-2.708-.754-.754-1.657-1.13-2.708-1.13-1.051 0-1.954.376-2.708 1.13-.754.755-1.13 1.657-1.13 2.708 0 1.052.376 1.954 1.13 2.708z" />
          </svg>
        </i>
      )}
      {icon === 'catering' && (
        <i>
          <svg width="18" height="17" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.21 17l1.333-1.326-6.484-6.45 1.355-1.37c.738.345 1.567.404 2.486.18.918-.225 1.754-.704 2.507-1.438a5.438 5.438 0 001.288-1.967c.271-.726.361-1.427.271-2.1-.09-.675-.361-1.237-.813-1.686-.452-.45-1.013-.72-1.683-.81-.67-.09-1.367 0-2.09.27a5.496 5.496 0 00-1.966 1.281c-.738.75-1.227 1.58-1.468 2.495-.24.914-.188 1.737.158 2.472L.91 15.674 2.242 17l6.484-6.45L15.21 17zM5.066 9.584L7.71 6.888 1.09.348a3.787 3.787 0 00-.96 1.686 3.617 3.617 0 000 1.921 3.79 3.79 0 00.96 1.674l3.976 3.955z"
              fill="#FFC627"
              fillRule="nonzero"
            />
          </svg>
        </i>
      )}
    </button>
  );
  return (
    <>
      {externalLink && (
        <>
          {href === 'soon' ? (
            <span>{Button}</span>
          ) : (
            <a href={href} target="_blank" rel="noreferrer noopener">
              {Button}
            </a>
          )}
        </>
      )}
      {!externalLink && (
        <>
          {href === 'soon' ? (
            <span>{Button}</span>
          ) : (
            <Link to={href}>{Button} </Link>
          )}
        </>
      )}
    </>
  );
};

export default ButtonBase;
