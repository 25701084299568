import React from 'react';
// import { Link } from 'react-router-dom';
import cx from 'classnames';

import { NavLink } from '..';
import styles from '../../Header/Header.scss';
import * as ROUTES from '../../../constants/routes';

// import socialFb from '../../../images/icons/icon_social_facebook.svg';
// import socialIm from '../../../images/icons/icon_social_instagram.svg';
// import socialTw from '../../../images/icons/icon_social_twitter.svg';

const NavBase = props => {
  return (
    <nav className={styles.nav}>
      <ul>
        <li>
          <NavLink to={ROUTES.HOME} {...props}>
            <i>
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="13">
                <path d="M6.284 12.72H2.66V6.871H.47L7.756.305l7.284 6.566H12.85v5.85H9.226V8.341H6.284z" />
              </svg>
            </i>
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to={ROUTES.GAMES} {...props}>
            <i>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="13">
                <g>
                  <path d="M7.094 8.904c.002-.768-.617-1.37-1.408-1.373-.79-.001-1.417.603-1.418 1.365 0 .76.632 1.37 1.417 1.366.785-.004 1.408-.604 1.41-1.358M14.2 6.157h-4.167c-.636.001-1.116.257-1.385.821-.072.151-.108.33-.114.498-.021.56.002 1.123-.034 1.682-.082 1.266-1.241 2.387-2.555 2.449-.887.042-1.777.021-2.665.027-.373.003-.746.004-1.119-.003a.312.312 0 0 1-.196-.077 6.623 6.623 0 0 1-1.853-3.51C-.471 4.963 1.259 1.845 4.234.588A7.261 7.261 0 0 1 7.331.004c3.094.101 5.672 2.061 6.558 4.817.136.425.205.87.312 1.336" />
                  <path d="M12.784 10.262V11.63h3.182v1.359h-4.603v-2.727h-.291c-.327 0-.654-.003-.98.002-.109.002-.154-.023-.152-.14.007-.402.003-.805.003-1.22.062-.003.11-.007.16-.007 1.9 0 3.8.001 5.7-.004.147 0 .18.043.178.175-.01.34-.011.68 0 1.019.005.149-.05.179-.193.178-.94-.005-1.88-.003-2.82-.003h-.184z" />
                </g>
              </svg>
            </i>
            Vikings Games
          </NavLink>
        </li>
        <li>
          <NavLink to={ROUTES.EVENTS} {...props}>
            <i>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
                <path d="M10.397 7.98v3.284H7.113V7.981h3.284zM9.302.869h1.642v1.094h.82c.365 0 .685.137.958.41.274.274.41.593.41.958v9.302c0 .365-.136.684-.41.958-.273.273-.593.41-.957.41H1.368c-.365 0-.684-.137-.958-.41a1.312 1.312 0 0 1-.41-.958V3.33c0-.365.137-.684.41-.958.274-.273.593-.41.958-.41h.82V.868H3.83v1.094h5.472V.868zm2.463 11.764v-7.25H1.368v7.25h10.397z" />
              </svg>
            </i>
            Events
          </NavLink>
        </li>
        <li>
          <NavLink to={ROUTES.MAPS} {...props}>
            <i>
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="16">
                <path d="M.66 13.72h10.19v1.436H.66V13.72zm3.624-8.755c0 .397.146.735.438 1.015.292.28.639.42 1.042.42.402 0 .747-.14 1.033-.42a1.37 1.37 0 0 0 .429-1.015c0-.397-.146-.741-.438-1.033a1.413 1.413 0 0 0-1.033-.438c-.397 0-.741.146-1.033.438a1.413 1.413 0 0 0-.438 1.033zm5.848 0c0 .607-.151 1.301-.455 2.084a13.796 13.796 0 0 1-1.085 2.153 25.042 25.042 0 0 1-1.296 1.891c-.35.467-.712.917-1.086 1.349l-.455.525-.455-.525a24.885 24.885 0 0 1-1.086-1.349 25.042 25.042 0 0 1-1.296-1.89 13.796 13.796 0 0 1-1.085-2.154c-.304-.783-.456-1.477-.456-2.084 0-.794.196-1.526.587-2.198a4.346 4.346 0 0 1 1.593-1.593A4.288 4.288 0 0 1 5.755.587c.794 0 1.526.196 2.198.587a4.346 4.346 0 0 1 1.593 1.593c.391.672.586 1.404.586 2.198z" />
              </svg>
            </i>
            Maps
          </NavLink>
        </li>
        {/* <li>
          <NavLink to={ROUTES.GALLERY} {...props}>
            <i>
              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12">
                <g>
                  <path d="M12.802 1.925v8.094l-7.096 1.925V0l7.096 1.925M2.789 11.39V.553c.55.193 1.087.378 1.62.574.043.015.073.095.094.152.015.04.003.091.003.137 0 3.036-.002 6.072.006 9.108 0 .21-.063.293-.242.35-.491.16-.977.338-1.481.514M0 1.092c.53.185 1.04.36 1.548.546.044.016.075.095.096.152.015.04.003.091.003.137 0 2.697-.001 5.394.004 8.09 0 .19-.042.284-.223.341-.474.149-.94.324-1.428.496V1.092z" />
                </g>
              </svg>
            </i>
            Gallery
          </NavLink>
        </li> */}
        {/* <li>
          <NavLink to={ROUTES.PREMIUMSEATINGSTAFF} {...props}>
            <i>
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15">
                <path d="M10.794 7.178c0 .188-.07.35-.208.482a.705.705 0 0 1-.507.2h-6.92L.397 10.655V.94c0-.189.067-.35.2-.482a.657.657 0 0 1 .482-.2h9c.2 0 .368.067.507.2a.644.644 0 0 1 .208.482v6.238zm2.761-4.159c.189 0 .35.067.483.2s.2.294.2.482v10.397l-2.762-2.761H3.84a.657.657 0 0 1-.483-.2.657.657 0 0 1-.2-.482V9.257h9V3.02h1.397z" />
              </svg>
            </i>
            Premium Seating Staff
          </NavLink>
        </li> */}
      </ul>
      <div
        className={cx(
          styles.header__top__content__social,
          styles.header__bottom__content__social__mobile,
        )}
      >
        {/* <Link to="">
          <img src={socialFb} alt="" />
        </Link>
        <Link to="">
          <img src={socialIm} alt="" />
        </Link>
        <Link to="">
          <img src={socialTw} alt="" />
        </Link> */}
      </div>
    </nav>
  );
};

export default NavBase;
