/* eslint-disable no-unused-vars */
import React from 'react';
import { config } from '../../../config';
import { Button } from '../../components/shared';

import styles from './Events.scss';

const EventItem = props => {
  const { item } = props;
  const {
    _id,
    dateMonth,
    dateDay,
    dateDay1,
    dateDay2,
    poster,
    title,
    tbd,
  } = item;
  // console.log('poster', poster);
  // console.log('proccess.', process.env.NODE_ENV);
  return (
    <div className={styles.event}>
      <div className={styles.event__top}>
        <div className={styles.event__top__title}>
          <h2>{title}</h2>
          <img src={`${config.BASE_URL}/${poster.path}`} alt="" />
        </div>
        {!tbd && (
          <div className={styles.event__top__date}>
            <span>{dateMonth}</span>
            {dateDay || `${dateDay1} & ${dateDay2}`}
          </div>
        )}
        {tbd && (
          <div className={styles.event__top__date}>
            <span style={{ textAlign: 'center', fontSize: '14px' }}>
              Postponed <br />
              (TBD)
            </span>
          </div>
        )}
      </div>
      <div className={styles.event__bottom}>
        {_id && (
          <Button text="Event Info" arrow href={`events/${_id}`} icon="arrow" />
        )}
      </div>
    </div>
  );
};

export default EventItem;
