import React from 'react';

const links = [
  {
    id: 0,
    title: 'My Vikings Ticketing Login',
    href: 'https://am.ticketmaster.com/vikings/',
  },
  {
    id: 1,
    title: 'Aramark Suite Catering',
    href: 'https://www.suitecatering.com/select-venue/',
  },
  {
    id: 2,
    title: 'Northern Lights – Omni Viking Lakes Hotel',
    href:
      'https://www.omnihotels.com/hotels/viking-lakes-minnesota/specials/northern-lights',
  },
  {
    id: 25,
    title: 'Luxury Game Day Transportation: Total Transportation',
    href: 'https://totallimo.com/',
  },
  {
    id: 3,
    title: 'Suite Experience Group',
    href: 'https://www.suiteexperiencegroup.com/ ',
  },
  // {
  //   id: 4,
  //   title: 'Taylor Merchandise Ordering',
  //   href: 'https://vikingssuiteholders.orders.com/store/home',
  // },
  {
    id: 5,
    title: 'TicketManager',
    href: 'https://ticketmanager.com/',
  },
  {
    id: 6,
    title: 'Digital Ticketing Information',
    href: 'https://www.vikings.com/tickets/digital-ticketing',
  },
  {
    id: 7,
    title: 'U.S. Bank Stadium',
    href: 'https://www.usbankstadium.com/',
  },
  {
    id: 8,
    title: 'Vikings Locker Room Store',
    href: 'https://www.vikingsfanshop.com/',
  },
  {
    id: 9,
    title: 'Vikings.com',
    href: 'https://www.vikings.com/',
  },
  {
    id: 10,
    title: 'Vikings Food Truck',
    href:
      'https://minnesotavikings.formstack.com/forms/vikings_table_event_request',
  },

  // {
  //   id: 10,
  //   title: 'Vikings Premium Seating',
  //   href: 'https://www.vikings.com/tickets/premium-seating/',
  // },
];

export default function ExtLinksBase() {
  return links.map(link => (
    <a href={link.href} key={link.id} target="_blank" rel="noopener noreferrer">
      <i>
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
          <path d="M7 0l7 7-7 7-1.237-1.237 4.884-4.904H0V6.141h10.647L5.763 1.237z" />
        </svg>
      </i>
      {link.title}
    </a>
  ));
}
