import React, { useState } from 'react';
import cx from 'classnames';

import MapsHeader from '../../shared/MapsHeader';
import AccordionItem from '../AccordionItem';

import styles from '../../Maps.scss';

import pdf from '../../../../files/MillsFleetFarm_Garage.pdf';

const images = require.context('../../../../images/maps', true);

const parkingInfo = [
  {
    id: 0,
    title: 'I-394 EASTBOUND',
    directionItems: [
      'Follow I-394 EB towards downtown.',
      'Use left two lanes to take the 4th St. N. exit.',
      'Follow 4th St S towards Park Ave.',
      'Turn left onto Park Ave.',
      'Mills Fleet Farm parking garage will be on your right.',
    ],
  },
  {
    id: 1,
    title: 'I-35W NORTHBOUND',
    directionItems: [
      'Take I-35W N to S 3rd St',
      'Exit 17C (3rd St U of M)',
      'Follow 3rd St and turn left on Chicago Ave',
      'Mills Fleet Farm Parking Garage is on the right.',
    ],
  },
  {
    id: 2,
    title: 'I-35W SOUTHBOUND',
    directionItems: [
      'Take I-35W SB to Exit 17C towards Washington Ave.',
      'Use Right two lanes to turn right onto S Washington Ave.',
      'Turn left onto Chicago Ave.',
      'The Mills Fleet Farm parking garage will be on your right.',
    ],
  },
  {
    id: 3,
    title: 'I-94 EASTBOUND',
    directionItems: [
      'Follow I-94 E to Exit 230 for 4th St N.',
      'Keep right at the fork, follow signs for 2nd Ave N.',
      'Continue straight on 4th St. N towards Hennepin Ave.',
      'Continue straight on 4th St. S towards Park Ave.',
      'Turn left onto Park Ave.',
      'The Mills Fleet Farm parking garage will be on your right',
    ],
  },
  {
    id: 4,
    title: 'I-94 WESTBOUND',
    directionItems: [
      'Follow I-94 West to Exit 234B 7th St.',
      'Follow 7th St S towards Park Ave.',
      'Turn right onto Park Ave.',
      'The Mills Fleet Farm parking garage will be on your right.',
    ],
  },
];
function isMobileDevice() {
  return (
    typeof window.orientation !== 'undefined' ||
    navigator.userAgent.indexOf('IEMobile') !== -1
  );
}
const renderDirections = directions => {
  const [activeTab, setActiveTab] = useState(isMobileDevice() ? null : 0);

  return directions.map((item, i) => {
    return (
      <AccordionItem
        key={item.id.toString()}
        id={item.id}
        data={item}
        openTab={i === 0}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    );
  });
};

const ParkingMffg = () => {
  return (
    <div className={styles.maps__tabs}>
      <MapsHeader title="Stadium Parking Ramp" />
      <div
        className={cx(styles.maps__tabs__map, styles.maps__tabs__map__parking)}
      >
        <div className={styles.maps__tabs__map__download}>
          <a href={pdf} download="Parking - Stadium Parking Ramp Map">
            <div className={styles.download__pdf}>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18">
                <path d="M.016 15.6h13.968v2.016H.016V15.6zm13.968-9L7 13.584.016 6.6H4v-6h6v6h3.984z" />
              </svg>
              <span>PDF</span>
            </div>
          </a>
        </div>
        <div className={styles.maps__tabs__map__parking__left}>
          <h3>
            Directions to <span>Stadium Parking Ramp</span>
          </h3>
          <h4>740 4TH ST, MINNE APOLIS MN, 55415</h4>
          <div className={styles.parking__accordion}>
            {renderDirections(parkingInfo)}
          </div>
        </div>
        <div className={styles.maps__tabs__map__parking__right}>
          <img
            src={images(`./map_parking_mills-fleet-farm-garage.jpg`)}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default ParkingMffg;
