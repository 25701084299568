import React from 'react';
import cx from 'classnames';
import MapsHeader from '../shared/MapsHeader';

import styles from '../Maps.scss';

import pdf from '../../../files/upper-concourse-maps.pdf';

const images = require.context('../../../images/maps', true);

const UpperConcourse = () => {
  return (
    <div className={styles.maps__tabs}>
      <MapsHeader title="Upper Concourse" />

      <div className={styles.maps__tabs__map}>
        <div className={styles.maps__tabs__map__download}>
          <a href={pdf} download="Main Upper Concourse Map">
            <div className={styles.download__pdf}>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18">
                <path d="M.016 15.6h13.968v2.016H.016V15.6zm13.968-9L7 13.584.016 6.6H4v-6h6v6h3.984z" />
              </svg>
              <span>PDF</span>
            </div>
          </a>
        </div>

        <img src={images(`./concourse_upper.jpg`)} alt="" />
      </div>

      <div className={styles.maps__tabs__info}>
        <div className={styles.maps__tabs__info__content}>
          <div className={styles.map_info__icons}>
            <div className={styles.map_info__icons__item}>
              <img src={images(`./icon-map_blue.svg`)} alt="" />
              <span>concessions</span>
            </div>
            <div className={styles.map_info__icons__item}>
              <img src={images(`./icon-map_green.svg`)} alt="" />
              <span>u.s. bank atms</span>
            </div>
            <div className={styles.map_info__icons__item}>
              <img src={images(`./icon-map_yellow.svg`)} alt="" />
              <span>guest services</span>
            </div>
            <div className={styles.map_info__icons__item}>
              <img src={images(`./icon-map_red.svg`)} alt="" />
              <span>3m first aid stations</span>
            </div>
            <div className={styles.map_info__icons__item}>
              <img src={images(`./icon-map_pink.svg`)} alt="" />
              <span>mamava lactation suite</span>
            </div>
          </div>
          <div
            className={cx(
              styles.map_info__list,
              styles['map_info__list--yellow'],
            )}
          >
            <div className={styles.map_info__list__header}>
              <h3>gold lobby</h3>
              <h4>access to the following:</h4>
            </div>
            <div className={styles.map_info__list__items}>
              <span>polaris club</span>
              <span>Little six club gold</span>
              <span>mystic lake’s club purple</span>
              <span>norseman suites 1-15</span>
              <span>nw/ne norseman lounges</span>
              <span>loft suites 1-27</span>
              <span>truss bar</span>
              <span>cabin suites</span>
              <span>lodge bar</span>
            </div>
          </div>
          <div
            className={cx(
              styles.map_info__list,
              styles['map_info__list--purple'],
            )}
          >
            <div className={styles.map_info__list__header}>
              <h3>purple lobby</h3>
              <h4>access to the following:</h4>
            </div>
            <div className={styles.map_info__list__items}>
              <span>delta sky360° club</span>
              <span>medtronic club</span>
              <span>fmp club</span>
              <span>turf & touchdown suites</span>
              <span>valhalla suites</span>
              <span>norseman suites 16-27</span>
              <span>se norseman lounge</span>
              <span>loft suites 28-46</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpperConcourse;
