import React, { useState } from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';

import styles from './Tabs.scss';

const RenderTabs = props => {
  const { children, active, setActive, history } = props;

  return children.map((item, i) => {
    const tabClicked = () => {
      const currentUrl = window.location.href.split('/');
      const pageName = currentUrl[4];
      const clientName = currentUrl[3];
      history.push(`/${clientName}/${pageName}`);
      setActive(i);
    };
    if (i % 2 === 0) {
      const activeTab =
        active === i ? styles['tabs__header__items__item--selected'] : '';
      return (
        <button
          onClick={() => tabClicked()}
          className={cx(styles.tabs__header__items__item, activeTab)}
          key={children[i].props.id}
          tabIndex={i}
        >
          {item}
        </button>
      );
    }
    return false;
  });
};

const RenderTabsWithRouter = withRouter(RenderTabs);

const RenderBody = props => {
  const { children, active } = props;

  return children.map((item, i) => {
    if (i - 1 === active) {
      return (
        <div className="content" key={children[i].props.id}>
          {item}
        </div>
      );
    }
    return false;
  });
};

const TabsBase = props => {
  const { children, container } = props;
  const [active, setActive] = useState(0);
  const withContainer = container ? styles.tabs__body__container : '';

  return (
    <div className={styles.tabs}>
      <div className={styles.tabs__header}>
        <div className={styles.tabs__header__items}>
          <RenderTabsWithRouter setActive={setActive} active={active}>
            {children}
          </RenderTabsWithRouter>
        </div>
      </div>
      <div className={cx(styles.tabs__body, withContainer)}>
        <RenderBody active={active}>{children}</RenderBody>
      </div>
    </div>
  );
};

export default TabsBase;
