/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { config } from '../../../config';
import { clientInfoContext } from '../../contexts/ClientInfoContext';
import { Button } from '../../components/shared';
import styles from './Events.scss';
import { useGlobalState } from '../../contexts/GlobalState';

const EventDetails = props => {
  const [allEvents] = useGlobalState('events');
  const [event, setEvent] = useState();

  const { history, match, client } = props;
  const { params } = match;

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log('event', event);
    if (!event && allEvents)
      setEvent(allEvents.filter(e => e._id === params.id)[0]);
  }, [client, allEvents, event]);

  return (
    <div className={styles.event__details}>
      {event && (
        <>
          <div className={styles.event__details__top}>
            <div className={styles.event__details__top__left}>
              <div className={styles.event__details__top__left__btn}>
                <div
                  className={styles.btn__back}
                  onClick={() => history.goBack()}
                  role="button"
                  tabIndex="0"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="19"
                  >
                    <path d="M19.948 12.65v4.726a.952.952 0 0 1-.283.701.938.938 0 0 1-.689.283.98.98 0 0 1-.701-.283.932.932 0 0 1-.295-.701V12.65c0-1.51-.534-2.802-1.6-3.876-1.066-1.075-2.354-1.612-3.864-1.612H4.15l2.781 2.78c.18.198.27.431.27.702 0 .27-.094.5-.282.69a.938.938 0 0 1-.69.282.958.958 0 0 1-.7-.295l-4.406-4.38c-.18-.197-.27-.431-.27-.702 0-.27.09-.504.27-.701L5.65 1.035A.958.958 0 0 1 6.352.74c.27 0 .5.094.689.283a.938.938 0 0 1 .283.689c0 .27-.09.504-.27.701L4.247 5.22h8.268c2.051 0 3.803.726 5.255 2.178 1.451 1.452 2.177 3.203 2.177 5.254z" />
                  </svg>
                </div>
                <Button
                  text="reserve this event!"
                  href={event.reserveUrl}
                  icon="arrow"
                  externalLink
                />
              </div>
              <div className={styles.event__details__top__left__title}>
                <h2>{event.title}</h2>
              </div>
              <div className={styles.event__details__top__left__desc}>
                {event.desc}
              </div>
              <div className={styles.event__details__top__left__when}>
                <div className={styles.event__details__sub_title}>
                  <h3>When</h3>
                </div>
                {!event.tbd && event.dateDay && (
                  <>
                    {event.dateMonth} {event.dateDay}
                  </>
                )}
                {!event.tbd && event.dateDay1 && event.dateDay2 && (
                  <>
                    {event.dateMonth} {event.dateDay1} & {event.dateDay2}
                  </>
                )}
                {event.tbd && <>Postponed – TBD</>}
                {event.time && <span> @ </span>}
                {event.time}
              </div>
              <div className={styles.event__details__top__left__where}>
                <div className={styles.event__details__sub_title}>
                  <h3>Where</h3>
                </div>
                {event.venue}
              </div>
              {event.price && (
                <div className={styles.event__details__top__left__price}>
                  <div className={styles.event__details__sub_title}>
                    <h3>
                      <span>Your</span> per-ticket price
                    </h3>
                  </div>
                  {event.price === 'Contact for relocation suite price' ? (
                    <p>Contact for relocation suite price</p>
                  ) : (
                    <>
                      <span>$</span>
                      {event.price}
                      <p>(Must Purchase Full Suite)</p>
                    </>
                  )}
                </div>
              )}
              <div className={styles.event__details__top__left__deadline}>
                <div className={styles.event__details__sub_title}>
                  <h3>deadline to reserve tickets to this event</h3>
                </div>
                {event.deadline}
              </div>
            </div>
            <div className={styles.event__details__top__right}>
              <div className={styles.event__details__right__cover}>
                <img src={`${config.BASE_URL}/${event.poster.path}`} alt="" />
              </div>
            </div>
          </div>
          {event.reserveUrl && (
            <div className={styles.event__details__bottom}>
              {event.reserveUrl === 'soon' ? (
                <span className={styles.btn__reserve__soon}>
                  <div className={styles.btn__reserve}>
                    reserve this event soon!
                  </div>
                </span>
              ) : (
                <a
                  href={event.reserveUrl}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <div className={styles.btn__reserve}>reserve this event!</div>
                </a>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

// export default EventDetails;
export default React.forwardRef((props, ref) => (
  <clientInfoContext.Consumer>
    {client => <EventDetails {...props} client={client} ref={ref} />}
  </clientInfoContext.Consumer>
));
