import React from 'react';

import { PageHeader, Tabs } from '../../components/shared';
import { pageContext } from '../../contexts/PageContext';
import MapStadium from './Stadium/MapStadium';
import MapParking from './Parking/MapParking';

import styles from './Maps.scss';

const MapsBase = () => {
  return (
    <pageContext.Provider value="maps">
      <div className={styles.maps}>
        <div className={styles.maps__content}>
          <PageHeader title="Maps" />
          <Tabs>
            <span id="h1"> Stadium</span>
            <MapStadium id="b1" />
            <span id="h2"> Parking</span>
            <MapParking id="b2" />
          </Tabs>
        </div>
      </div>
    </pageContext.Provider>
  );
};

export default MapsBase;
