export const HOME = '/:userId';
export const GAMES = '/:userId/games';
export const EVENTS = '/:userId/events';
export const EVENTS_DETAILS = '/:userId/events/:id';
export const MAPS = '/:userId/maps';
export const GALLERY = '/:userId/gallery';
export const GALLERY_DETAILS = '/:userId/gallery/:id';
export const PREMIUMSEATINGSTAFF = '/:userId/staff';
// export const BLOG = '/blog';
// export const BLOG_DETAILS = '/blog/:id';
