import React, { useState } from 'react';
import cx from 'classnames';

import MapsHeader from '../../shared/MapsHeader';
import AccordionItem from '../AccordionItem';

import styles from '../../Maps.scss';

import pdf from '../../../../files/Hcmc_Ramp.pdf';

const images = require.context('../../../../images/maps', true);

const parkingInfo = [
  {
    id: 0,
    title: 'I-394 EASTBOUND',
    directionItems: [
      'Follow I-394 towards downtown.',
      'Use Exit 9B and follow signs for N 6th St.',
      'Continue straight onto N 6th St.',
      'Continue on 6th St N. towards Portland Ave S.',
      'HCMC Ramp will be on the right. ',
    ],
  },
  {
    id: 1,
    title: 'I-35W NORTHBOUND',
    directionItems: [
      'Take exit 17C for 3rd St.',
      'Turn left onto Washington Ave S.',
      'Turn left on Portland Ave S',
      'Turn left on S 6th St.',
      'HCMC Ramp is on the immediate right.',
    ],
  },
  {
    id: 2,
    title: 'I-35W SOUTHBOUND',
    directionItems: [
      'Take I-35W SB to Exit 17B towards 11th Ave.',
      'Use right lane towards I-94 WB/11th Ave.',
      'Stay in the right lane towards 11th Ave S.',
      'Turn right onto 11th Ave S towards 7th St. S.',
      'Turn left onto 7th St S.',
      'Right on Portland Ave.',
      'Right on 6th St.',
      'HCMC Ramp will be on the right.',
    ],
  },
  {
    id: 3,
    title: 'I-94 EASTBOUND',
    directionItems: [
      'Follow I-94 E to Exit 230 for 4th St N.',
      'Keep right at the fork, follow signs for 2nd Ave N.',
      'Continue straight on 4th St. N towards Hennepin Ave.',
      'Continue straight on 4th St. S towards 3rd Ave S.',
      'Turn right onto 3rd Ave S towards 6th St S.',
      'Turn left onto 6th St S.',
      'HCMC Ramp will be on the right. ',
    ],
  },
  {
    id: 4,
    title: 'I-94 WESTBOUND',
    directionItems: [
      'Follow I-94 West to Exit 234B 7th St.',
      'Follow 7th St exit.',
      'Follow 7th St S towards Park Ave.',
      'Right on Portland Ave.',
      'Right on 6th St.',
      'HCMC Ramp will be on the right.',
    ],
  },
];
function isMobileDevice() {
  return (
    typeof window.orientation !== 'undefined' ||
    navigator.userAgent.indexOf('IEMobile') !== -1
  );
}
const renderDirections = directions => {
  const [activeTab, setActiveTab] = useState(isMobileDevice() ? null : 0);

  return directions.map((item, i) => {
    return (
      <AccordionItem
        key={item.id.toString()}
        id={item.id}
        data={item}
        openTab={i === 0}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    );
  });
};

const ParkingHcmcRamp = () => {
  return (
    <div className={styles.maps__tabs}>
      <MapsHeader title="HCMC Ramp" />
      <div
        className={cx(styles.maps__tabs__map, styles.maps__tabs__map__parking)}
      >
        <div className={styles.maps__tabs__map__download}>
          <a href={pdf} download="Parking - HCMC Ramp Map">
            <div className={styles.download__pdf}>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18">
                <path d="M.016 15.6h13.968v2.016H.016V15.6zm13.968-9L7 13.584.016 6.6H4v-6h6v6h3.984z" />
              </svg>
              <span>PDF</span>
            </div>
          </a>
        </div>
        <div className={styles.maps__tabs__map__parking__left}>
          <h3>
            Directions to <span>HCMC Ramp</span>
          </h3>
          <h4>600 PARK AVE, MINNEAPOLIS MN, 55415</h4>
          <div className={styles.parking__accordion}>
            {renderDirections(parkingInfo)}
          </div>
        </div>
        <div className={styles.maps__tabs__map__parking__right}>
          <img src={images(`./map_parking_HCMC-ramp.jpg`)} alt="" />
        </div>
      </div>
    </div>
  );
};

export default ParkingHcmcRamp;
