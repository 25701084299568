import React from 'react';

import styles from '../Maps.scss';

const MapsHeader = props => {
  const { title } = props;

  return (
    <div className={styles.maps__tabs__header}>
      <h2>{title}</h2>
    </div>
  );
};

export default MapsHeader;
