const config = {};

config.subDomain = 'premium-cms';
config.cmsLocal = false;
config.BASE_URL = config.cmsLocal
  ? 'http://localhost:8888'
  : `https://${config.subDomain}.vikings.c1ms.com`;

config.API_BASE_URL = config.cmsLocal
  ? 'http://localhost:8888/api/collections/get'
  : `https://${config.subDomain}.vikings.c1ms.com/api/collections/get`;
config.API_BASE_URL_PARAMS =
  '?populate=nonsense&populate_module=2&token=account-6eaca1184f2f6109e981a347e1cc5a';
config.API_BASE_URL_PARAMS_NO_POPULATE =
  '?populate_module=2&token=account-6eaca1184f2f6109e981a347e1cc5a&suite=';

module.exports = {
  config,
};
