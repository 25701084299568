/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import axios from 'axios';

import {
  BrowserRouter as Router,
  Route,
  withRouter,
  Switch,
  Redirect,
} from 'react-router-dom';
import { config } from '../config';
import { GlobalStateProvider, useGlobalState } from './contexts/GlobalState';
import { clientInfoContext } from './contexts/ClientInfoContext';

import './styles/reset.css';
import styles from './App.scss';

import Home from './pages/Home';
import Games from './pages/Games';
import Events from './pages/Events';
import Maps from './pages/Maps';
import Header from './components/Header';
import Footer from './components/Footer';

import * as ROUTES from './constants/routes';

const FooterWithRouter = withRouter(Footer);
const HeaderWithRouter = withRouter(Header);

const App = () => {
  const clientName = window.location.href.split('/')[3] || 'default';
  const [userInfo, useUserInfo] = useState({});
  const [, update] = useGlobalState('userId');
  const [, setEvents] = useGlobalState('events');
  const [, setSuiteName] = useGlobalState('suiteName');
  const [, setGames] = useGlobalState('games');

  useEffect(() => {
    update(clientName === '' ? 'default' : clientName);
    const fetchData = async query => {
      axios
        .get(
          `${config.API_BASE_URL}/suite${
            config.API_BASE_URL_PARAMS
          }&filter[purl]=${query}`,
        )
        .then(
          response => {
            const { data } = response;
            if (data.entries && data.entries.length > 0) {
              const { name, owner_name, type } = data.entries[0];

              const result = {
                suite_name: name,
                owner_name,
                suite_type: type.display,
              };
              data.entries[0] ? useUserInfo(result) : null;
              const suiteName = encodeURIComponent(name);
              setSuiteName(suiteName);
              axios
                .get(
                  `${config.API_BASE_URL}/event${
                    config.API_BASE_URL_PARAMS_NO_POPULATE
                  }&suite=${suiteName}`,
                )
                .then(
                  res => {
                    const { data: eventData } = res;
                    if (eventData && eventData.entries) {
                      eventData.entries[0]
                        ? setEvents(eventData.entries)
                        : null;
                    }
                  },
                  error => {
                    console.log(error);
                  },
                );
            }
          },
          error => {
            console.log(error);
          },
        );

      axios
        .get(`${config.API_BASE_URL}/game${config.API_BASE_URL_PARAMS}`)
        .then(
          response => {
            const { data } = response;
            if (data.entries && data.entries.length > 0) {
              const _games = [];
              data.entries.forEach((game, i) => {
                const {
                  _id,
                  isTbd,
                  date,
                  time,
                  preSeason,
                  opponent: { againstCity, againstName, logo, againstColor },
                } = game;
                const _game = {
                  id: _id,
                  preSeason,
                  againstCity,
                  againstName,
                  againstImg: logo.path,
                  againstColor,
                  isTbd,
                  venue: 'MINNEAPOLIS, MINN.',
                  date: isTbd ? 'TBD' : `${new Date(`${date} ${time} (CDT)`)}`,
                };
                _games.push(_game);
              });
              setGames(_games);
            }
          },
          error => {
            console.log(error);
          },
        );
    };

    fetchData(clientName);
  }, [clientName]);
  // console.log('events', events);
  return (
    <div className={styles.main}>
      <Router>
        <GlobalStateProvider>
          <clientInfoContext.Provider value={userInfo}>
            <HeaderWithRouter />
            <div className={styles.body}>
              <Switch>
                <Redirect exact from="/" to="/default" />
                <Route path={ROUTES.HOME} exact component={Home} />
                <Route path={ROUTES.GAMES} component={Games} />
                <Route path={ROUTES.EVENTS} component={Events} />
                <Route path={ROUTES.MAPS} component={Maps} />
                {/* <Route path={ROUTES.GALLERY} component={Gallery} /> */}
                {/* <Route
                  path={ROUTES.PREMIUMSEATINGSTAFF}
                  component={PremiumSeatingStaff}
                /> */}
              </Switch>
              <FooterWithRouter />
            </div>
          </clientInfoContext.Provider>
        </GlobalStateProvider>
      </Router>
    </div>
  );
};

export default hot(App);
