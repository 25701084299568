import React, { useState } from 'react';
import cx from 'classnames';

import MapsHeader from '../../shared/MapsHeader';
import AccordionItem from '../AccordionItem';

import styles from '../../Maps.scss';

import pdf from '../../../../files/1010_Ramp.pdf';

const images = require.context('../../../../images/maps', true);

const parkingInfo = [
  {
    id: 0,
    title: 'I-394 EASTBOUND',
    directionItems: [
      'Follow I-394 EB towards downtown.',
      'Take Exit 9B towards 6th St N.',
      'Continue straight onto 6th St N. towards 10th Ave S.',
      'Turn right onto 10th Ave S.',
      '1010 Ramp will be on the left. ',
    ],
  },
  {
    id: 1,
    title: 'I-35W NORTHBOUND',
    directionItems: [
      'Take I-35W N to S Washington Ave',
      'Exit 17C, stay right, and turn left on S Washington Ave',
      'Follow Washington Ave and turn left onto 11th Ave S',
      'Turn right on S 7th St',
      'Take the next right on 10th Ave S',
      'Ramp entrance is on the right',
    ],
  },
  {
    id: 2,
    title: 'I-35W SOUTHBOUND',
    directionItems: [
      'Take I-35W SB to Exit 17B towards 11th Ave.',
      'Use right lane towards I-94 WB/11th Ave.',
      'Stay in the right lane towards 11th Ave S.',
      'Turn right onto 11th Ave S towards 7th St. S.',
      'Turn left onto 7th St S. towards 10th Ave S.',
      'Turn right onto 10th Ave S.',
      '1010 Ramp will be on the right. ',
    ],
  },
  {
    id: 3,
    title: 'I-94 EASTBOUND',
    directionItems: [
      ' Follow I-94 E to Exit 230 for 4th St N.',
      'Keep right at the fork, follow signs for 2nd Ave N.',
      'Continue straight on 4th St. N towards Hennepin Ave.',
      'Continue straight on 4th St. S towards 3rd Ave S.',
      'Turn right onto 3rd Ave S towards 6th St S.',
      'Turn left onto 6th St S towards 10th St S.',
      'Turn right onto 10th Ave S.',
      '1010 Ramp will be on the left. ',
    ],
  },
  {
    id: 4,
    title: 'I-94 WESTBOUND',
    directionItems: [
      'Follow I-94 West to Exit 234B 7th St.',
      'Follow 7th St exit.',
      'Follow 7th St S.',
      'Turn right onto 10th Ave S.',
      '1010 Ramp will be on the right. ',
    ],
  },
];
function isMobileDevice() {
  return (
    typeof window.orientation !== 'undefined' ||
    navigator.userAgent.indexOf('IEMobile') !== -1
  );
}
const renderDirections = directions => {
  const [activeTab, setActiveTab] = useState(isMobileDevice() ? null : 0);

  return directions.map((item, i) => {
    return (
      <AccordionItem
        key={item.id.toString()}
        id={item.id}
        data={item}
        openTab={i === 0}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
    );
  });
};

const Parking1010Ramp = () => {
  return (
    <div className={styles.maps__tabs}>
      <MapsHeader title="1010 Ramp" />
      <div
        className={cx(styles.maps__tabs__map, styles.maps__tabs__map__parking)}
      >
        <div className={styles.maps__tabs__map__download}>
          <a href={pdf} download="Parking - 1010 Ramp Map">
            <div className={styles.download__pdf}>
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18">
                <path d="M.016 15.6h13.968v2.016H.016V15.6zm13.968-9L7 13.584.016 6.6H4v-6h6v6h3.984z" />
              </svg>
              <span>PDF</span>
            </div>
          </a>
        </div>
        <div className={styles.maps__tabs__map__parking__left}>
          <h3>
            Directions to <span>1010 Ramp</span>
          </h3>
          <h4>1010 S 7TH ST, MINNE APOLIS MN, 55415</h4>
          <div className={styles.parking__accordion}>
            {renderDirections(parkingInfo)}
          </div>
        </div>
        <div className={styles.maps__tabs__map__parking__right}>
          <img src={images(`./map_parking_1010-ramp.jpg`)} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Parking1010Ramp;
