import React, { useState, useEffect } from 'react';
import cx from 'classnames';

import styles from '../Maps.scss';

const AccordionItem = props => {
  const { data, id, openTab, activeTab, setActiveTab } = props;
  const { directionItems } = data;

  const [open, setOpen] = useState(false);
  const openToggle = open ? styles.open : '';

  const renderDirectionItems = directionItems.map((item, i) => (
    <li key={i.toString()}>{item}</li>
  ));

  const openTabHandler = () => {
    setActiveTab(id);
    open ? setOpen(false) : setOpen(true);
  };
  useEffect(() => {
    if (openTab) {
      setOpen(true);
    }
    activeTab === id ? setOpen(true) : setOpen(false);
  }, [openTab, activeTab]);

  return (
    <a role="button" tabIndex="0" onClick={() => openTabHandler()}>
      <div className={cx(styles.parking__accordion__item, openToggle)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="31"
          height="31"
          className={styles.parking__accordion__item__close}
        >
          <g>
            <path d="M.887 27.678L27.677.887l2.436 2.435-26.79 26.791z" />
            <path d="M3.322.887l26.791 26.79-2.435 2.436L.887 3.323z" />
          </g>
        </svg>
        <h2>{data.title}</h2>
        <ul>{renderDirectionItems}</ul>
      </div>
    </a>
  );
};

export default AccordionItem;
