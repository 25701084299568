import React from 'react';
import cx from 'classnames';
import styles from './PageHeader.scss';

function PageHeaderBase(props) {
  const { title } = props;

  return (
    <div
      className={cx(styles.header, styles[`header--${title.toLowerCase()}`])}
    >
      <h1 className={styles.header__title}>{title}</h1>
    </div>
  );
}
export default PageHeaderBase;
