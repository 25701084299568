import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { useGlobalState } from '../../../contexts/GlobalState';

import stylesHeader from '../../Header/Header.scss';
import stylesFooter from '../../Footer/Footer.scss';

const NavLinkBase = props => {
  const { children, to, location, navOpen } = props;
  const { pathname } = location;

  const [value] = useGlobalState('userId');
  const [notificationGallery] = useGlobalState('notificationGallery');
  const [notificationEvents] = useGlobalState('notificationEvents');

  const closeNav = () => {
    document.body.classList.remove(stylesHeader.modal__open);
    navOpen ? navOpen(false) : null;
  };

  const toHref = to.split(':userId').join(value);
  const isActive = location
    ? pathname.split('/')[2] === toHref.split('/')[2]
    : '';

  return (
    <Link
      to={toHref}
      className={cx(
        isActive ? cx(stylesFooter.active, stylesHeader.active) : '',
        toHref.split('/')[2] === 'gallery' && notificationGallery
          ? cx(stylesHeader.notification__on, stylesFooter.notification__on)
          : '',
        toHref.split('/')[2] === 'events' && notificationEvents
          ? cx(stylesHeader.notification__on, stylesFooter.notification__on)
          : '',
      )}
      onClick={closeNav}
    >
      {children}
    </Link>
  );
};

export default NavLinkBase;
