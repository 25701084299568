/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-const */
import React, { useState, useEffect } from 'react';
import { useGlobalState } from '../../contexts/GlobalState';

import { preSeasonItems, regular2021SeasonItems } from './gamesData';

import styles from './Games.scss';

import {
  BoxRow,
  BoxContainer,
  PageHeader,
  Tabs,
} from '../../components/shared';

import { pageContext } from '../../contexts/PageContext';

const rowItemsPreGames = (preSeasonGames, activeGamePre, setActiveGamePre) => {
  // console.log('preSeasonItems', preSeasonItems);
  return preSeasonGames.map(item => (
    <BoxRow
      item={item}
      key={item.id}
      active={activeGamePre}
      setActive={setActiveGamePre}
    />
  ));
};

const rowItemsRegularGames = (
  regSeasonGame,
  activeGamePre,
  setActiveGamePre,
) => {
  // console.log('🚀 ~ file: Games.js:37 ~ regSeasonGame:', regSeasonGame);
  return regSeasonGame.map(item => (
    <BoxRow
      item={item}
      key={item.id}
      active={activeGamePre}
      setActive={setActiveGamePre}
    />
  ));
};

const GamesPage = () => {
  const [preSeasonGame, setPreSeasonGame] = useState([]);
  const [regSeasonGame, setRegSeasonGame] = useState([]);
  const [activeGamePre, setActiveGamePre] = useState();
  const [activeGameSeason, setActiveGameSeason] = useState();

  const [allGames] = useGlobalState('games');
  useEffect(() => {
    if (allGames) {
      let _preSeasonGames = [];
      let _regSeasonGames = [];
      allGames.forEach(game => {
        if (game.preSeason) {
          _preSeasonGames.push(game);
        } else {
          _regSeasonGames.push(game);
        }
      });
      setPreSeasonGame(_preSeasonGames);
      // console.log('_preSeasonGames[0]?.id', _preSeasonGames[0].id);
      // setActiveGamePre(_preSeasonGames[0].id);
      setRegSeasonGame(_regSeasonGames);
      setActiveGameSeason(_regSeasonGames[0].id);
    }
  }, [allGames]);
  if (!allGames) return 'Loading...';
  return (
    <pageContext.Provider value="games">
      <div className={styles.games}>
        <div className={styles.games__content}>
          <PageHeader title="Viking Games" />
          {/* <Tabs container>
            <span id="h1"> Preseason</span>
            <BoxContainer id="b1">
              {rowItemsPreGames(activeGamePre, setActiveGamePre)}
            </BoxContainer>
            <span id="h2"> regular season</span>
            <BoxContainer id="b2">
              {rowItemsRegularGames(activeGameSeason, setActiveGameSeason)}
            </BoxContainer>
          </Tabs> */}
          <div className={styles.container}>
            <div className={styles.games_section}>
              <h2>Preseason</h2>
              {rowItemsPreGames(preSeasonGame, activeGamePre, setActiveGamePre)}
            </div>
            <div className={styles.games_section}>
              <h2>Regular Season</h2>
              {rowItemsRegularGames(
                regSeasonGame,
                activeGameSeason,
                setActiveGameSeason,
              )}
            </div>
          </div>
        </div>
      </div>
    </pageContext.Provider>
  );
};

export default GamesPage;
