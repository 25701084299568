import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { useGlobalState, openNav, closeNav } from '../../contexts/GlobalState';
import styles from './Header.scss';
import { Nav, ExtLinks } from '../shared';
import logo from '../../images/logo.svg';
import * as ROUTES from '../../constants/routes';

const icons = require.context('../../images/icons', true);

function titleCase(pathname) {
  return (
    pathname.charAt(0).toUpperCase() + pathname.slice(1).replace(/\/.*$/, '')
  );
}

const HeaderBase = props => {
  const { match, location } = props;
  const { path } = match;
  const { pathname } = location;
  const [open, setOpen] = useGlobalState('openNav');
  const [notificationGallery] = useGlobalState('notificationGallery');
  const [notificationEvents] = useGlobalState('notificationEvents');
  const [value] = useGlobalState('userId');

  const openToggle = open ? styles.open : '';
  const pathStr = pathname.split('/')[2];
  const headerBody = React.createRef();

  const pageName = pathStr ? titleCase(pathStr) : 'Home';
  const openNavHandler = () => {
    if (open) {
      document.body.classList.remove(styles.modal__open);
      const topPos = headerBody.current.offsetTop;
      headerBody.current.scrollTop = topPos;
      closeNav();
    } else {
      document.body.classList.add(styles.modal__open);
      openNav();
    }
  };
  return (
    <header className={styles.header}>
      <div className={styles.header__top}>
        <div className={cx(styles.header__top__content, openToggle)}>
          <div className={styles.header__top__content__close}>
            <button
              onClick={() => openNavHandler()}
              className={styles.header__top__content__close__btn}
            >
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="22" height="2" y="-2" rx="1" fill="black" />
                <rect y="7" width="22" height="2" rx="1" fill="black" />
              </svg>
            </button>
          </div>
          <div className={styles.header__top__content__home}>
            <i>
              <img
                src={icons(`./icon-header-${pageName.toLowerCase()}.svg`)}
                alt=""
              />
            </i>
            {pageName}
          </div>
          <div className={styles.header__top__content__logo}>
            <img src={logo} alt="logo" />
            <svg
              width="27"
              height="60"
              viewBox="0 0 27 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 60H1.48026L27 -1.78814e-06H0V60Z" fill="white" />
            </svg>
          </div>

          {(notificationGallery || notificationEvents) && (
            <div className={styles.header__top__content__right}>
              <div className={styles.header__top__content__right__label}>
                recently updated
              </div>
              {notificationGallery && (
                <div className={styles.header__top__content__right__item}>
                  <Link to={ROUTES.GALLERY.split(':userId').join(value)}>
                    Gallery
                  </Link>
                </div>
              )}
              {notificationEvents && (
                <div className={styles.header__top__content__right__item}>
                  <Link to={ROUTES.EVENTS.split(':userId').join(value)}>
                    Events
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div ref={headerBody} className={cx(styles.header__bottom, openToggle)}>
        <div className={styles.header__bottom__content}>
          <Nav navOpen={setOpen} {...props} />
          <div className={styles.header__bottom__content__links}>
            <h4>important links</h4>
            <div className={styles.links}>
              <ExtLinks />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderBase;
