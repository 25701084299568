import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Transition, TransitionGroup } from 'react-transition-group';

import { fade } from '../../constants/routeAnimation';
import { PageHeader } from '../../components/shared';
import { pageContext } from '../../contexts/PageContext';
import EventsList from './EventsList';
import * as ROUTES from '../../constants/routes';
import EventsDetails from './EventDetails';

import styles from './Events.scss';

const EventsBase = ({ location }) => {
  const { key } = location;

  const eventsContent = React.createRef();

  return (
    <pageContext.Provider value="events">
      <div className={styles.events}>
        <PageHeader title="Events" />
        <div className={styles.events__content} ref={eventsContent}>
          <TransitionGroup component={null}>
            <Transition
              key={key}
              onEnter={node => fade(node)}
              onExit={node => fade(node)}
              timeout={{ enter: 300, exit: 300 }}
            >
              <Switch>
                <Route path={ROUTES.EVENTS} exact component={EventsList} />
                <Route
                  path={ROUTES.EVENTS_DETAILS}
                  exact
                  component={EventsDetails}
                />
              </Switch>
            </Transition>
          </TransitionGroup>
        </div>
      </div>
    </pageContext.Provider>
  );
};

export default EventsBase;
