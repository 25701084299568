import React, { useState } from 'react';
import cx from 'classnames';

import styles from './TabsMaps.scss';

const RenderTabs = props => {
  const { children, active, setActive } = props;

  return children.map((item, i) => {
    if (i % 2 === 0) {
      const activeTab =
        active === i ? styles['tabs_maps__header__items__item--selected'] : '';
      return (
        <button
          onClick={() => setActive(i)}
          className={cx(styles.tabs_maps__header__items__item, activeTab)}
          key={children[i].props.id}
          tabIndex={i}
        >
          {item}
        </button>
      );
    }
    return false;
  });
};

const RenderBody = props => {
  const { children, active } = props;

  return children.map((item, i) => {
    if (i - 1 === active) {
      return (
        <div
          key={children[i].props.id}
          className={styles.tabs_maps__body__fade}
        >
          {item}
        </div>
      );
    }
    return false;
  });
};

const TabsMapsBase = props => {
  const { children } = props;
  const [active, setActive] = useState(0);

  return (
    <div className={cx(styles.tabs_maps, styles.tabs_maps__fade)}>
      <div className={styles.tabs_maps__header}>
        <div className={styles.tabs_maps__header__items}>
          <RenderTabs setActive={setActive} active={active}>
            {children}
          </RenderTabs>
        </div>
      </div>
      <div className={cx(styles.tabs_maps__body)}>
        <RenderBody active={active}>{children}</RenderBody>
      </div>
    </div>
  );
};

export default TabsMapsBase;
