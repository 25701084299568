import { TimelineMax as Timeline, Power1 } from 'gsap';

const getDefaultTimeline = (node, delay) => {
  const timeline = new Timeline({ paused: true });

  timeline.from(node, 0.3, {
    display: 'none',
    autoAlpha: 0,
    delay,
    ease: Power1.easeIn,
  });

  return timeline;
};

export const fade = node => {
  const timeline = getDefaultTimeline(node, 0.3);
  requestAnimationFrame(() => timeline.play());
};
