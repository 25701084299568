import React from 'react';
import styles from './BoxRow.scss';
import { pageContext } from '../../../contexts/PageContext';
import { BoxRowItem } from '../index';

function BoxRowBase(props) {
  return (
    <pageContext.Consumer>
      {page => (
        <div className={styles.box__row}>
          <BoxRowItem {...props} page={page} />
        </div>
      )}
    </pageContext.Consumer>
  );
}

export default BoxRowBase;
