import React from 'react';
import cx from 'classnames';

import styles from './BoxRowItem.scss';
import GamesRowItems from './GamesRowItems';

function BoxRowItemBase(props) {
  const { item, page } = props;

  return (
    <div className={cx(styles.box__row, styles[`box__row--${page}`])}>
      {page === 'games' && <GamesRowItems item={item} {...props} />}
    </div>
  );
}

export default BoxRowItemBase;
