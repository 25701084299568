import React from 'react';

import { TabsMaps } from '../../../components/shared';
import {
  Parking511Lot,
  Parking511Ramp,
  Parking1010Ramp,
  ParkingDteRamp,
  ParkingHcmcRamp,
  ParkingMffg,
} from './ParkingLots';

const MapParking = () => {
  return (
    <TabsMaps>
      <span id="h2"> 511 lot</span>
      <Parking511Lot id="b2" />
      <span id="h3"> 511 ramp</span>
      <Parking511Ramp id="b3" />
      <span id="h4"> 1010 ramp</span>
      <Parking1010Ramp id="b4" />
      <span id="h5"> dte ramp</span>
      <ParkingDteRamp id="b5" />
      <span id="h6"> hcmc ramp</span>
      <ParkingHcmcRamp id="b6" />
      <span id="h7">Stadium Parking Ramp</span>
      <ParkingMffg id="b7" />
    </TabsMaps>
  );
};

export default MapParking;
