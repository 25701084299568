/* eslint-disable no-plusplus */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useGlobalState } from '../../contexts/GlobalState';

import EventItem from './EventItem';
import styles from './Events.scss';

function groupBy2(xs, prop) {
  const grouped = {};
  for (let i = 0; i < xs.length; i++) {
    const p = xs[i][prop];
    if (!grouped[p]) {
      grouped[p] = [];
    }
    grouped[p].push(xs[i]);
  }
  return grouped;
}

const EventsList = () => {
  const [allEvents] = useGlobalState('events');
  const [events, setEvents] = useState();
  useEffect(() => {
    if (allEvents) {
      setEvents(
        groupBy2(
          allEvents.sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
          }),
          'dateYear',
        ),
      );
    }
  }, [allEvents]);

  return (
    <div className={styles.events__content__list}>
      {events ? (
        Object.keys(events).map((key, i) => {
          const groupByMonth = groupBy2(events[key], 'dateMonth');
          return (
            <React.Fragment key={i * 100}>
              <h1 className={styles.events__content__list__year}>{key}</h1>
              {Object.keys(groupByMonth).map((month, l) => {
                return (
                  <React.Fragment key={l * 100}>
                    <div className={styles.events__content__list__header}>
                      <h2>{month}</h2>
                    </div>
                    <div className={styles.events__content__list__body}>
                      {groupByMonth[month].map(item => {
                        return <EventItem item={item} key={item._id} />;
                      })}
                    </div>
                  </React.Fragment>
                );
              })}
            </React.Fragment>
          );
        })
      ) : (
        <>No event found</>
      )}
    </div>
  );
};

export default EventsList;
