import React from 'react';
import FooterBase from './Footer';

const Footer = props => {
  const { location } = props;
  const isHome = location.pathname === '/';
  if (!isHome) {
    return <FooterBase {...props} />;
  }
  return null;
};

export default Footer;
