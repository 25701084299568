import React from 'react';
import styles from './Footer.scss';
import { Nav, ExtLinks } from '../shared';

import logo from '../../images/logo-white.svg';

const FooterBase = props => {
  return (
    <div className={styles.footer}>
      <div className={styles.footer__content}>
        <div className={styles.footer__content__logo}>
          <img src={logo} alt="" />
        </div>
        <div className={styles.footer__content__nav}>
          <Nav {...props} />
        </div>
        <div className={styles.footer__content__links}>
          <div className={styles.links}>
            <ExtLinks />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterBase;
