import React from 'react';

import { TabsMaps } from '../../../components/shared';
import MainConcourse from './MainConcourse';
import UpperConcourse from './UpperConcourse';

const MapStadium = () => {
  return (
    <TabsMaps>
      <span id="h1"> main concourse</span>
      <MainConcourse id="b1" />
      <span id="h2"> Upper Concourse</span>
      <UpperConcourse id="b2" />
    </TabsMaps>
  );
};

export default MapStadium;
